// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("/opt/build/repo/src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-i-want-to-code-mdx": () => import("/opt/build/repo/src/pages/i-want-to-code.mdx" /* webpackChunkName: "component---src-pages-i-want-to-code-mdx" */),
  "component---src-pages-retrospective-on-2014-mdx": () => import("/opt/build/repo/src/pages/retrospective-on-2014.mdx" /* webpackChunkName: "component---src-pages-retrospective-on-2014-mdx" */),
  "component---src-pages-creating-koality-react-native-templates-mdx": () => import("/opt/build/repo/src/pages/creating-koality-react-native-templates.mdx" /* webpackChunkName: "component---src-pages-creating-koality-react-native-templates-mdx" */),
  "component---src-pages-supercharged-vim-built-from-source-mdx": () => import("/opt/build/repo/src/pages/supercharged-vim-built-from-source.mdx" /* webpackChunkName: "component---src-pages-supercharged-vim-built-from-source-mdx" */),
  "component---src-pages-why-neovim-mdx": () => import("/opt/build/repo/src/pages/why-neovim.mdx" /* webpackChunkName: "component---src-pages-why-neovim-mdx" */),
  "component---src-pages-a-guide-to-basic-neovim-plugins-mdx": () => import("/opt/build/repo/src/pages/a-guide-to-basic-neovim-plugins.mdx" /* webpackChunkName: "component---src-pages-a-guide-to-basic-neovim-plugins-mdx" */),
  "component---src-pages-setting-up-vim-for-javascript-development-mdx": () => import("/opt/build/repo/src/pages/setting-up-vim-for-javascript-development.mdx" /* webpackChunkName: "component---src-pages-setting-up-vim-for-javascript-development-mdx" */),
  "component---src-pages-build-your-own-minimal-and-scriptable-terminal-mdx": () => import("/opt/build/repo/src/pages/build-your-own-minimal-and-scriptable-terminal.mdx" /* webpackChunkName: "component---src-pages-build-your-own-minimal-and-scriptable-terminal-mdx" */),
  "component---src-pages-setting-up-your-first-mastodon-server-mdx": () => import("/opt/build/repo/src/pages/setting-up-your-first-mastodon-server.mdx" /* webpackChunkName: "component---src-pages-setting-up-your-first-mastodon-server-mdx" */)
}

